import { useAuth0 } from "@auth0/auth0-react";
export function GetHeaders(headers) {
  const { user } = useAuth0();

  let values = {
    Authorization: headers["token"],
    "saas-user-id": user.sub,
    "saas-namespace": headers["namespace"],
    "saas-account-id": headers["accountId"],
    "saas-organisation-id": headers["organisationId"],
  };
  return values;
}
