import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Order from "./pages/order.js";
import Navbar from "./components/navbar.js";
import { Spin } from "antd";

export default function App() {
  const [namespace, setNamespace] = useState("");
  const [token, setToken] = useState("");
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const response = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUDIENCE,
            scope: process.env.REACT_APP_SCOPE,
          });
          setToken(response);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);
  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "200px",
        }}
      >
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isAuthenticated) {
    if (token != "") {
      return (
        <div>
          <Navbar value={{ setNamespace, namespace, token }} />
          <Order value={{ namespace, token }} />
        </div>
      );
    } else {
      return <div>...loading</div>;
    }
  } else {
    loginWithRedirect();
  }
}
