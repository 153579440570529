import OrderList from "../components/orderList.js";
import OrderDetails from "../components/orderDetails.js";
import { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { GetHeaders } from "../helpers/getHeaders.js";

export default function Order(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);

  const GET_ORDER_STATES = gql`
    query GetOrderStates {
      getOrderStates {
        ID
        TenantID
        Stage
        Label
        Type
        IsSystem
      }
    }
  `;
  const [getOrderStates, { loading, error, data, refetch }] = useLazyQuery(
    GET_ORDER_STATES,
    {
      fetchPolicy: "no-cache",
      context: {
        headers: GetHeaders({
          token: `bearer ${props.value.token}`,
          namespace: props.value.namespace,
        }),
      },
    }
  );
  useEffect(() => {
    setShowDetails(false);
    if (props.value.namespace) {
      getOrderStates();
    }
  }, [props.value.namespace]);
  return (
    <div style={{ overflow: "scroll" }}>
      {props.value.namespace ? (
        <OrderList
          value={{
            namespace: props.value.namespace,
            showDetails,
            setShowDetails,
            setSelectedOrder,
            setIsOrderUpdated,
            states: data,
            token: props.value.token,
          }}
        />
      ) : null}
      <OrderDetails
        value={{
          namespace: props.value.namespace,
          showDetails,
          setShowDetails,
          selectedOrder,
          isOrderUpdated,
          setIsOrderUpdated,
          states: data,
          token: props.value.token,
        }}
      />
    </div>
  );
}
