import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { GetHeaders } from "../helpers/getHeaders.js";
import { Select, Button, Drawer, Space, Descriptions, Modal } from "antd";
import { useState, useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function OrderDetails(props) {
  const { Option } = Select;
  let currentOrder = props.value.selectedOrder;
  const [unitPrice, setUnitPrice] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quantity, setQuantity] = useState();
  let date = new Date(currentOrder.createdAt);
  const UPDATE_ORGANISATION_ORDER = gql`
    mutation Mutation(
      $orderId: Int!
      $unitPrice: Float
      $quantity: Float
      $stateId: Int
    ) {
      updateOrganisationOrder(
        orderId: $orderId
        unitPrice: $unitPrice
        quantity: $quantity
        stateId: $stateId
      ) {
        ID
        Items {
          ID
          createdAt
          Description
          Quantity
          ClosedQuantity
          QuantityUnit
          UnitPrice
          TotalAmount
          DiscountAmount
          Currency
          Metadata
          ItemMetadata {
            ListingImages {
              url
            }
          }
          ProductImage
          ProductRef
          ProductName
          ProductSnapshotID
          ProductSnapshot {
            ID
            Data {
              product_name
            }
          }
          StateID
          State {
            ID
            TenantID
            Label
            Stage
            Type
            IsSystem
            createdAt
          }
          StateMachineID
          OrderID
        }
        createdAt
        TenantID
        TotalAmount
        DiscountAmount
        Currency
        BuyerID
        SellerID
        ShippingAddressID
        BillingInfoID
        StateID
        StateMachineID
        ConfigID
        BillingInfo {
          ID
          BillingAddressID
          Gstn
          Pan
        }
        State {
          ID
          TenantID
          Stage
          Label
          Type
          IsSystem
          createdAt
        }
        Config
        StateMachine
        Price
        Metadata {
          DiscountCode
          BidReference
          SellerShippingAddressID
          SellerBillingAddressID
          ListingReference
          SourceLocation {
            Pincode
            District
            State
          }
          OrganisationID
        }
        SellerShippingAddress {
          id
          ownerRef
          address
          area
          pincode
          country
          label
          location {
            lat
            long
          }
        }
        SellerAccountDetails {
          id
          name
        }
      }
    }
  `;
  const GET_ORDER_STATE_MACHINES = gql`
    query GetOrderStateMachines($orderStateId: ID!) {
      getOrderStateMachines(orderStateId: $orderStateId) {
        ID
        Stage
        Label
      }
    }
  `;
  const [
    updateOrganisationOrder,
    { data: updatedData, loading: updatedLoading, error: updatedError },
  ] = useMutation(UPDATE_ORGANISATION_ORDER, {
    context: {
      headers: GetHeaders({
        token: `bearer ${props.value.token}`,
        namespace: props.value.namespace,
        accountId: parseInt(currentOrder.BuyerID),
      }),
    },
  });
  const [getOrderStateMachines, { data: stateMachineData }] = useLazyQuery(
    GET_ORDER_STATE_MACHINES,
    {
      fetchPolicy: "no-cache",
      context: {
        headers: GetHeaders({
          token: `bearer ${props.value.token}`,
          namespace: props.value.namespace,
        }),
      },
    }
  );
  useEffect(() => {
    if (props.value.showDetails == true) {
      getOrderStateMachines({
        variables: { orderStateId: currentOrder.Items[0].State.ID },
      });
    }
  }, [props.value.showDetails]);
  useEffect(() => {
    if (updatedData) {
      getOrderStateMachines({
        variables: {
          orderStateId:
            updatedData?.updateOrganisationOrder.Items[0]?.State?.ID,
        },
      });
    }
  }, [updatedData]);
  const updateOrderStatus = (value, event) => {
    Modal.confirm({
      title: `Order Status`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          <span style={{ fontSize: "15px" }}>
            Are you sure you want to mark your order as
          </span>
          <br />
          <span
            style={{
              border: "1px solid grey",
              padding: "3px",
              background: "#D3D3D3",
              borderRadius: "5%",
            }}
          >
            {event.value}
          </span>
          ?
        </p>
      ),
      okText: "Yes",
      onOk: () => {
        updateOrganisationOrder({
          variables: {
            orderId: parseInt(currentOrder.ID),
            stateId: parseInt(event.key),
          },
        });
        props.value.setIsOrderUpdated(true);
      },
      cancelText: "No",
    });
  };

  const saveModifiedChanges = () => {
    let variables = {
      orderId: parseInt(currentOrder.ID),
    };
    if (unitPrice != currentOrder.Items[0].UnitPrice) {
      variables["unitPrice"] = parseFloat(unitPrice);
    }
    if (quantity != currentOrder.Items[0].Quantity) {
      variables["quantity"] = parseFloat(quantity);
    }
    updateOrganisationOrder({ variables: variables });
    setIsModalVisible(false);
    props.value.setIsOrderUpdated(true);
  };
  return (
    <div>
      {currentOrder.Items ? (
        <Drawer
          title="Order Details"
          placement="right"
          width={window.innerWidth >= 740 ? "736px" : "100%"}
          onClose={() => props.value.setShowDetails(false)}
          visible={props.value.showDetails}
        >
          {currentOrder.Items[0]?.ItemMetadata?.ListingImages[0]?.url ? (
            <img
              style={{ maxHeight: "450px" }}
              height="100%"
              width="100%"
              className="img-fluid"
              src={currentOrder.Items[0]?.ItemMetadata?.ListingImages?.[0]?.url}
              alt=" listing image"
            />
          ) : null}

          <Descriptions bordered>
            <Descriptions.Item style={{ width: "180px" }} label="Sku Name">
              {currentOrder.Items[0]?.ProductName}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions bordered>
            <Descriptions.Item style={{ width: "180px" }} label="Address">
              {currentOrder.Metadata?.SourceLocation?.District},
              {currentOrder.Metadata?.SourceLocation?.State} -
              {currentOrder.Metadata?.SourceLocation?.Pincode}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered>
            <Descriptions.Item style={{ width: "180px" }} label="Seller Id">
              {currentOrder.SellerAccountDetails.id}
            </Descriptions.Item>
            <Descriptions.Item label="Seller Name">
              {currentOrder.SellerAccountDetails.name}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered>
            <Descriptions.Item label="Unit Price">
              <>
                {updatedData?.updateOrganisationOrder &&
                props.value.isOrderUpdated == true ? (
                  <>
                    {Number(
                      updatedData.updateOrganisationOrder.Items[0]?.UnitPrice
                    ).toFixed(1)}
                  </>
                ) : (
                  <>{Number(currentOrder.Items[0]?.UnitPrice).toFixed(1)}</>
                )}{" "}
                Rs
              </>
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              <>
                {" "}
                {updatedData?.updateOrganisationOrder &&
                props.value.isOrderUpdated == true ? (
                  <div>
                    {Number(
                      updatedData.updateOrganisationOrder.Items[0]?.Quantity
                    ).toFixed(1)}
                  </div>
                ) : (
                  <div>
                    {Number(currentOrder.Items[0]?.Quantity).toFixed(1)}
                  </div>
                )}{" "}
                Kg
              </>
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount" span={1}>
              {updatedData?.updateOrganisationOrder &&
              props.value.isOrderUpdated == true ? (
                <div>
                  {Number(
                    updatedData.updateOrganisationOrder.Items[0]?.TotalAmount
                  ).toFixed(1)}
                </div>
              ) : (
                <div>
                  {Number(currentOrder.Items[0]?.TotalAmount).toFixed(1)}
                </div>
              )}
              Rs
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {updatedData?.updateOrganisationOrder &&
              props.value.isOrderUpdated == true ? (
                !stateMachineData ? (
                  <>
                    {updatedData.updateOrganisationOrder.Items[0]?.State.Label}
                  </>
                ) : (
                  <>
                    <Select
                      style={{ minWidth: "200px" }}
                      placeholder={
                        updatedData.updateOrganisationOrder.Items[0]?.State
                          .Label
                      }
                      onChange={(value, event) =>
                        updateOrderStatus(value, event)
                      }
                      value={
                        updatedData.updateOrganisationOrder.Items[0]?.State
                          .Label
                      }
                    >
                      {stateMachineData
                        ? stateMachineData.getOrderStateMachines.map((item) => {
                            return (
                              <Option key={item.ID} value={item.Label}>
                                {item.Label}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </>
                )
              ) : !stateMachineData ? (
                <>{currentOrder.Items[0].State.Label}</>
              ) : (
                <>
                  <Select
                    style={{ minWidth: "200px" }}
                    placeholder={currentOrder.Items[0].State.Label}
                    onChange={(value, event) => updateOrderStatus(value, event)}
                    value={currentOrder.Items[0].State.Label}
                  >
                    {stateMachineData
                      ? stateMachineData.getOrderStateMachines.map((item) => {
                          return (
                            <Option key={item.ID} value={item.Label}>
                              {item.Label}
                            </Option>
                          );
                        })
                      : null}
                  </Select>
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered>
            <Descriptions.Item style={{ width: "180px" }} label="Purchased On">
              Date: {date.toISOString().substring(0, 10)}
              <br />
              Time: {date.toTimeString()}
            </Descriptions.Item>
          </Descriptions>
          <br />
          {props.value.isOrderUpdated == true ? (
            !stateMachineData ? null : (
              <Space size="large" wrap>
                <Button type="primary" onClick={() => setIsModalVisible(true)}>
                  Edit Order
                </Button>
              </Space>
            )
          ) : !stateMachineData ? null : (
            <Space size="large" wrap>
              <Button type="primary" onClick={() => setIsModalVisible(true)}>
                Edit Order
              </Button>
            </Space>
          )}
        </Drawer>
      ) : null}
      <Modal
        title="EDIT ORDER"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText="Save Changes"
        onOk={saveModifiedChanges}
      >
        <div>
          Unit Price:{" "}
          <input
            onChange={(e) => setUnitPrice(e.target.value)}
            style={{
              border: "1px solid grey",
              width: "50px",
              borderRadius: "5px",
            }}
            type="text"
            defaultValue={
              updatedData?.updateOrganisationOrder &&
              props.value.isOrderUpdated == true
                ? updatedData.updateOrganisationOrder.Items[0].UnitPrice
                : currentOrder.Items?.[0]?.UnitPrice
            }
          />{" "}
          Rs
        </div>
        <br />
        <div>
          Quantity:{" "}
          <input
            onChange={(e) => setQuantity(e.target.value)}
            style={{
              border: "1px solid grey",
              width: "50px",
              borderRadius: "5px",
            }}
            type="text"
            defaultValue={
              updatedData?.updateOrganisationOrder &&
              props.value.isOrderUpdated == true
                ? updatedData.updateOrganisationOrder.Items[0].Quantity
                : currentOrder.Items?.[0]?.Quantity
            }
          />{" "}
          Kg
        </div>
      </Modal>
    </div>
  );
}
