import { useAuth0 } from "@auth0/auth0-react";
import styles from "../styles/common/navbar.module.scss";
import { gql, useQuery } from "@apollo/client";
import { GetHeaders } from "../helpers/getHeaders.js";
import { Menu, Dropdown, Button, Space } from "antd";
import { useEffect } from "react";
export default function Navbar(props) {
  let menu;
  const { logout } = useAuth0();
  const GET_MY_NAMESPACES = gql`
    query GetMyNamespaces {
      getMyNamespaces {
        id
        code
        name
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_MY_NAMESPACES, {
    fetchPolicy: "no-cache",
    context: {
      headers: GetHeaders({
        token: `bearer ${props.value.token}`,
      }),
    },
  });
  useEffect(() => {
    props.value.setNamespace(data?.getMyNamespaces?.[0]?.code);
  }, [data]);
  if (data) {
    menu = (
      <Menu>
        {data.getMyNamespaces.map((item) => {
          return (
            <Menu.Item
              key={item.id}
              onClick={() => props.value.setNamespace(item.code)}
            >
              {item.code}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  } else {
    menu = <Menu></Menu>;
  }
  return (
    <div id={styles.container}>
      <p id={styles.title}>Fishgram</p>
      <div>
        <Space>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            {props.value.namespace ? (
              <Button shape="round">{props.value.namespace}</Button>
            ) : (
              <Button shape="round">Select Namespace</Button>
            )}
          </Dropdown>
          <Button
            onClick={() => logout({ returnTo: window.location.origin })}
            shape="round"
          >
            Logout
          </Button>
        </Space>
      </div>
    </div>
  );
}
